import React from "react"
import FontIcon from "../FontIcons/FontIcon"
import { navigate, Link } from "gatsby"
import * as styles from "./Nav.module.scss"
import { clickEvent } from "../../utils/analytics"
import Logo from "../Svg/Logo"
import classNames from "classnames"
import LanguageSelector from "./LanguageSelector"

const linksSe = [
  {
    label: "Start",
    icon: <FontIcon>&#xe88a;</FontIcon>,
    to: "/",
  },
  {
    label: "Aktier",
    icon: <FontIcon>&#xe896;</FontIcon>,
    to: "/aktier/",
  },
  {
    label: "Analyshus",
    icon: <FontIcon>&#xf1d0;</FontIcon>,
    to: "/analyshus/",
  },
  {
    label: "Mer",
    icon: <FontIcon>&#xe5d3;</FontIcon>,
    to: "/mer/",
  },
]

const linksEn = [
  {
    label: "Start",
    icon: <FontIcon>&#xe88a;</FontIcon>,
    to: "/en/",
  },
  {
    label: "Stocks",
    icon: <FontIcon>&#xe896;</FontIcon>,
    to: "/en/stocks/",
  },
  {
    label: "Analysts",
    icon: <FontIcon>&#xf1d0;</FontIcon>,
    to: "/en/analysts/",
  },
  {
    label: "More",
    icon: <FontIcon>&#xe5d3;</FontIcon>,
    to: "/en/more/",
  },
]

const TopNavItem = ({ link, index, activeIndex, onNavChange }) => {
  return (
    <div
      // index={index}
      onClick={(e) => onNavChange(index, e)}
      className={classNames(styles.topnavitem, {
        [styles.activetoptab]: activeIndex === index,
      })}
    >
      {index === 4 && link.icon}
      <span>{link.label}</span>
    </div>
  )
}

const BottomNavItem = ({ link, index, activeIndex, onNavChange }) => {
  const [activeTab, setActiveTab] = React.useState(false)

  React.useEffect(() => {
    setActiveTab(false)
  }, [activeIndex])

  return (
    <div
      // index={index}
      onClick={(e) => onNavChange(index, e)}
      onMouseDown={() => setActiveTab(activeIndex !== index)}
      onTouchStart={() => setActiveTab(activeIndex !== index)}
      onTouchMove={() => setActiveTab(false)}
      onMouseMove={() => setActiveTab(false)}
      className={classNames(
        styles.bottomnavitem,
        { [styles.bottomnavitemactive]: activeIndex === index },
        { [styles.activetab]: activeTab }
      )}
    >
      {link.icon}
      <span
        className={classNames({
          [styles.bottomspanactive]: activeIndex === index,
        })}
      >
        {link.label}
      </span>
    </div>
  )
}

const Nav = ({ location, lang }) => {
  const [theActiveIndex, setTheActiveIndex] = React.useState(location.pathname)

  const links = lang === "en" ? linksEn : linksSe

  React.useEffect(() => {
    setTheActiveIndex(location.pathname)
  }, [location])

  const getIndex = (location) => {
    if (location.pathname === "/" || location.pathname === "/en/") {
      return 0
    }

    if (
      location.pathname?.includes("/aktier/") ||
      location.pathname?.includes("/en/stocks/")
    ) {
      return 1
    }
    if (
      location.pathname === "/analyshus/" ||
      location.pathname === "/en/analysts/"
    ) {
      return 2
    }

    if (location.pathname === "/mer/" || location.pathname === "/en/more/") {
      return 3
    }

    return -1
  }

  const handleNavChange = (activeIndex) => {
    if (activeIndex === 4) {
      navigate(lang === "en" ? "/en/search/" : "/sok/")
      return
    }

    navigate(links[activeIndex].to)

    setTheActiveIndex(activeIndex)
    clickEvent("Navigation", links[activeIndex].label, true)
  }

  const index = getIndex(location)

  const activeIndex = index === -1 ? theActiveIndex : index

  const searchLink = {
    label: lang === "en" ? "" : "",
    icon: <FontIcon>&#xe8b6;</FontIcon>,
    to: lang === "en" ? "/en/search/" : "/sok/",
  }

  return (
    <>
      <nav
        className={classNames(styles.topnavigation, styles.topnav)}
        data-nosnippet
      >
        <Link to={lang === "en" ? "/en/" : "/"} className={styles.logo}>
          <Logo width={35} height={35} />
          <div>Riktkurs.nu</div>
        </Link>
        <LanguageSelector
          lang={lang}
          className={styles.topnavitemwithouthover}
        />
        {[...links, searchLink].map((link, index) => (
          <TopNavItem
            key={index}
            link={link}
            index={index}
            activeIndex={activeIndex}
            onNavChange={handleNavChange}
          />
        ))}
      </nav>
      <nav className={styles.bottomnav} data-nosnippet>
        {links.map((link, index) => (
          <BottomNavItem
            key={index}
            link={link}
            index={index}
            activeIndex={activeIndex}
            onNavChange={handleNavChange}
          />
        ))}
      </nav>
    </>
  )
}

export default Nav
