import React from "react"
import cn from "classnames"

interface FontIconProps {
  className?: string
  children: any
  onClick?: any
}

const FontIcon = ({ children, className, ...props }: FontIconProps) => {
  return (
    <i {...props} className={cn("material-icons", className)}>
      {children}
    </i>
  )
}

export default FontIcon
