import React from "react"
import FontIcon from "../FontIcons/FontIcon"
import { Link } from "gatsby"
import * as styles from "./LanguageSelector.module.scss"
import classNames from "classnames"

const LanguageSelector = ({ lang, ...rest }) => {
  return (
    <div {...rest}>
      <FontIcon className={styles.globe}>&#xe894;</FontIcon>{" "}
      <span>
        <Link
          to="/en/"
          className={classNames(styles.option, {
            [styles.selected]: lang === "en",
          })}
        >
          EN
        </Link>{" "}
        <span>|</span>{" "}
        <Link
          to="/"
          className={classNames(styles.option, {
            [styles.selected]: lang !== "en",
          })}
        >
          SV
        </Link>
      </span>
    </div>
  )
}

export default LanguageSelector
