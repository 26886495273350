import React from "react"
import Nav from "../Nav/Nav"
import Footer from "../Footer/Footer"
import { Link as ReachLink } from "@reach/router"
import Logo from "../Svg/Logo"
import { Link } from "gatsby"
import classNames from "classnames"
import FontIcon from "../FontIcons/FontIcon"
import * as styles from "./Layout.module.scss"
import "./layout.scss"
import FooterEn from "../Footer/FooterEn"
import LanguageSelector from "../Nav/LanguageSelector"

const Layout = ({ pageContext, children, location }) => {
  const { layout, lang } = pageContext

  const post = layout === "post"
  const search =
    location &&
    (location.pathname === "/sok/" || location.pathname === "/en/search/")

  React.useEffect(() => {
    window.addEventListener("resize", setMinHeight)

    return () => {
      window.removeEventListener("resize", setMinHeight)
    }
  }, [])

  React.useEffect(() => {
    setMinHeight()
  })

  const setMinHeight = () => {
    if (!post) {
      const smallScreen = window.innerWidth < 945

      let minHeight = 0

      if (smallScreen) {
        minHeight = 488
      } else {
        minHeight = 543
      }

      document.getElementById(
        "layout"
      ).style.minHeight = `calc(100vh - ${minHeight}px)`
    }
  }

  return (
    <div>
      {!search && <Nav location={location} lang={lang} />}
      {!search && (
        <>
          <header className={classNames(styles.appbar, styles.mainappbar)}>
            <div className={styles.appbarrow}>
              <section
                className={classNames(
                  styles.section,
                  styles.sectionalignstart,
                  styles.backarrow
                )}
              >
                <Link
                  to={lang === "en" ? "/en/" : "/"}
                  style={{ display: "flex" }}
                  className={styles.logofill}
                >
                  <Logo width={35} height={35} />
                  <span className={styles.riktkurs}>Riktkurs.nu</span>
                </Link>
              </section>
              <section
                className={classNames(styles.section, styles.sectionalignend)}
              >
                <LanguageSelector
                  lang={lang}
                  className={styles.languageselector}
                />
                <Link
                  to={lang === "en" ? "/en/settings/" : "/installningar/"}
                  className={styles.settingsicon}
                >
                  <FontIcon>&#xe8b8;</FontIcon>
                </Link>
                <ReachLink
                  to={lang === "en" ? "/en/search/" : "/sok/"}
                  className={styles.searchicon}
                >
                  <FontIcon>&#xe8b6;</FontIcon>
                </ReachLink>
              </section>
            </div>
          </header>
        </>
      )}
      <div id="layout" className={styles.childcontainer}>
        {children}
      </div>
      {!search && <>{lang === "en" ? <FooterEn /> : <Footer />}</>}
    </div>
  )
}

export default Layout
