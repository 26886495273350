// extracted by mini-css-extract-plugin
export var adscontainer = "Layout-module--adscontainer--d3f7a";
export var appbar = "Layout-module--appbar--19b70";
export var appbarrow = "Layout-module--appbarrow--2b7ad";
export var backarrow = "Layout-module--backarrow--07207";
export var childcontainer = "Layout-module--childcontainer--a9bf4";
export var hideondesktop = "Layout-module--hideondesktop--54196";
export var hideonmobile = "Layout-module--hideonmobile--39ce4";
export var languageselector = "Layout-module--languageselector--37d55";
export var logo = "Layout-module--logo--35636";
export var logofill = "Layout-module--logofill--69967";
export var mainappbar = "Layout-module--mainappbar--715c8";
export var riktkurs = "Layout-module--riktkurs--8d744";
export var searchbox = "Layout-module--searchbox--28cbd";
export var searchicon = "Layout-module--searchicon--4a174";
export var searchline = "Layout-module--searchline--e13db";
export var section = "Layout-module--section--5f3b5";
export var sectionalignend = "Layout-module--sectionalignend--01097";
export var sectionalignstart = "Layout-module--sectionalignstart--d23d6";
export var settingsicon = "Layout-module--settingsicon--3287e";