exports.components = {
  "component---src-pages-saknas-tsx": () => import("./../../../src/pages/saknas.tsx" /* webpackChunkName: "component---src-pages-saknas-tsx" */),
  "component---src-templates-aktier-tsx": () => import("./../../../src/templates/aktier.tsx" /* webpackChunkName: "component---src-templates-aktier-tsx" */),
  "component---src-templates-analyst-page-template-tsx": () => import("./../../../src/templates/analyst-page-template.tsx" /* webpackChunkName: "component---src-templates-analyst-page-template-tsx" */),
  "component---src-templates-analytiker-tsx": () => import("./../../../src/templates/analytiker.tsx" /* webpackChunkName: "component---src-templates-analytiker-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-installningar-tsx": () => import("./../../../src/templates/installningar.tsx" /* webpackChunkName: "component---src-templates-installningar-tsx" */),
  "component---src-templates-mer-tsx": () => import("./../../../src/templates/mer.tsx" /* webpackChunkName: "component---src-templates-mer-tsx" */),
  "component---src-templates-not-found-template-tsx": () => import("./../../../src/templates/not-found-template.tsx" /* webpackChunkName: "component---src-templates-not-found-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-senaste-tsx": () => import("./../../../src/templates/senaste.tsx" /* webpackChunkName: "component---src-templates-senaste-tsx" */),
  "component---src-templates-sok-tsx": () => import("./../../../src/templates/sok.tsx" /* webpackChunkName: "component---src-templates-sok-tsx" */),
  "component---src-templates-stock-page-template-tsx": () => import("./../../../src/templates/stock-page-template.tsx" /* webpackChunkName: "component---src-templates-stock-page-template-tsx" */)
}

