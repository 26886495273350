// extracted by mini-css-extract-plugin
export var activetab = "Nav-module--activetab--0e3bb";
export var activetoptab = "Nav-module--activetoptab--8b232";
export var bottomnav = "Nav-module--bottomnav--cd231";
export var bottomnavitem = "Nav-module--bottomnavitem--05b2f";
export var bottomnavitemactive = "Nav-module--bottomnavitemactive--a8246";
export var bottomspanactive = "Nav-module--bottomspanactive--8afe8";
export var logo = "Nav-module--logo--1b2e5";
export var themeswitch = "Nav-module--themeswitch--e3a10";
export var topnav = "Nav-module--topnav--104b4";
export var topnavigation = "Nav-module--topnavigation--5481a";
export var topnavitem = "Nav-module--topnavitem--43cfe";
export var topnavitemwithouthover = "Nav-module--topnavitemwithouthover--f4aa9";
export var uppercase = "Nav-module--uppercase--7a128";