import React from "react"
import { get } from "local-storage"

export const ThemeContext = React.createContext({
  dark: get("dark"),
  setDark: (dark) => {
    return dark
  },
})

const ThemeProvider = ({ children }) => {
  const [dark, setDark] = React.useState(get("dark"))

  return (
    <ThemeContext.Provider
      value={{
        dark,
        setDark,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
