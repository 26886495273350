import React from "react"
import * as styles from "./Footer.module.scss"
import FontIcon from "../FontIcons/FontIcon"
import { Link, useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        buildTime
      }
    }
  `)

  return (
    <footer className={styles.footer}>
      <div className={styles.flexcontainer}>
        <div style={{ width: "50%", textAlign: "center" }}>
          <ul>
            <Link to="/om/">
              <li>
                <FontIcon>&#xe887;</FontIcon>{" "}
                <span className={styles.icontext}>Om Riktkurs.nu</span>
              </li>
            </Link>
            <Link to="/integritetspolicy/">
              <li>
                <FontIcon>&#xe88e;</FontIcon>{" "}
                <span className={styles.icontext}>Integritetspolicy</span>
              </li>
            </Link>
          </ul>
        </div>
        <div style={{ width: "50%", textAlign: "center" }}>
          <ul>
            <Link to="/kontakt/">
              <li>
                <FontIcon>&#xe87f;</FontIcon>{" "}
                <span className={styles.icontext}>Kontakt</span>
              </li>
            </Link>
            <Link to="/installningar/">
              <li>
                <FontIcon>&#xe8b8;</FontIcon>{" "}
                <span className={styles.icontext}>Inställningar</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div className={styles.disclaimer}>
        Informationen på Riktkurs.nu är endast för allmänna informationsändamål.
        Riktkurs.nu gör inga garantier av något slag, uttryckliga eller
        underförstådda, om fullständigheten, noggrannheten, tillförlitligheten,
        lämpligheten eller tillgängligheten med avseende på Riktkurs.nu eller
        informationen, produkterna, tjänsterna, eller relaterad information på
        Riktkurs.nu för något ändamål. Mottagaren rekommenderas att komplettera
        sitt beslutsunderlag med det material som bedöms nödvändigt. Vi
        reserverar oss för alla typer av fel samt eventuella driftstörningar av
        webbplatsen. Informationen på Riktkurs.nu ska inte ses som en
        rekommendation att köpa eller sälja ett värdepapper.
      </div>
      <div className={styles.text}>
        <div>© Riktkurs.nu {new Date().getFullYear()}</div>
      </div>
      <div className={styles.updated}>
        <div>Uppdaterad {new Date(data.site.buildTime).toLocaleDateString('sv-SE')} {new Date(data.site.buildTime).toLocaleTimeString('sv-SE')}</div>
      </div>
      <div style={{ minHeight: 70 }} />
    </footer>
  )
}

export default Footer
