import React from "react"
import * as styles from "./Footer.module.scss"
import FontIcon from "../FontIcons/FontIcon"
import { Link, useStaticQuery, graphql } from "gatsby"

const FooterEn = () => {
  const data = useStaticQuery(graphql`
  query HeaderQuery {
    site {
      buildTime
    }
  }
`)

  return (
    <footer className={styles.footer}>
      <div className={styles.flexcontainer}>
        <div style={{ width: "50%", textAlign: "center" }}>
          <ul>
            <Link to="/en/about/">
              <li>
                <FontIcon>&#xe887;</FontIcon>{" "}
                <span className={styles.icontext}>About Riktkurs.nu</span>
              </li>
            </Link>
            <Link to="/en/integrity-policy/">
              <li>
                <FontIcon>&#xe88e;</FontIcon>{" "}
                <span className={styles.icontext}>Integrity Policy</span>
              </li>
            </Link>
          </ul>
        </div>
        <div style={{ width: "50%", textAlign: "center" }}>
          <ul>
            <Link to="/en/contact/">
              <li>
                <FontIcon>&#xe87f;</FontIcon>{" "}
                <span className={styles.icontext}>Contact</span>
              </li>
            </Link>
            <Link to="/en/settings/">
              <li>
                <FontIcon>&#xe8b8;</FontIcon>{" "}
                <span className={styles.icontext}>Settings</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div className={styles.disclaimer}>
        The information on Riktkurs.nu is for general information purposes only.
        Riktkurs.nu makes no guarantees of any kind, express or implied, about
        the completeness, accuracy, reliability, suitability or availability
        with respect to Riktkurs.nu or the information, products, services, or
        related information on Riktkurs.nu for any purpose. The recipient is
        recommended to complete his or her decision basis with the material
        deemed necessary. We reserve us for all types of errors as well as any
        malfunctions of the website. The information on Riktkurs.nu should not
        be seen as a recommendation to buy or sell a security.
      </div>
      <div className={styles.text}>
        <div>© Riktkurs.nu {new Date().getFullYear()}</div>
      </div>
      <div className={styles.updated}>
        <div>Updated {new Date(data.site.buildTime).toLocaleDateString('en-US')} {new Date(data.site.buildTime).toLocaleTimeString('en-US')}</div>
      </div>
      <div style={{ minHeight: 70 }} />
    </footer>
  )
}

export default FooterEn
