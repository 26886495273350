import React from "react"

interface ILogo {
  fill?: string
  height: number
  width: number
}

const Logo = ({ fill, height, width }: ILogo) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path
        id="Selection"
        fill={fill}
        stroke="none"
        strokeWidth="1"
        d="M 431.00,20.00
           C 431.00,20.00 367.00,84.00 367.00,84.00
             367.00,84.00 334.00,118.00 334.00,118.00
             334.00,118.00 305.00,89.00 305.00,89.00
             300.76,84.77 295.51,78.37 289.00,78.37
             282.49,78.37 277.24,84.77 273.00,89.00
             273.00,89.00 240.00,122.00 240.00,122.00
             240.00,122.00 150.00,212.00 150.00,212.00
             150.00,212.00 120.00,242.00 120.00,242.00
             120.00,242.00 104.10,258.00 104.10,258.00
             93.04,270.28 102.86,279.87 111.00,278.62
             116.53,277.77 123.05,269.95 127.00,266.00
             127.00,266.00 159.00,234.00 159.00,234.00
             159.00,234.00 243.00,150.00 243.00,150.00
             243.00,150.00 285.00,108.11 285.00,108.11
             291.17,103.01 295.49,110.49 300.00,115.00
             300.00,115.00 320.00,135.00 320.00,135.00
             323.61,138.59 326.64,142.37 332.00,142.89
             339.23,143.59 342.28,139.69 347.00,135.00
             347.00,135.00 364.00,118.00 364.00,118.00
             364.00,118.00 446.00,36.00 446.00,36.00
             446.00,36.00 446.00,64.00 446.00,64.00
             446.02,67.88 445.99,71.61 448.65,74.77
             452.68,79.58 461.49,78.89 464.99,73.81
             467.23,70.58 466.99,66.74 467.00,63.00
             467.00,63.00 467.00,15.00 467.00,15.00
             466.98,4.66 465.34,0.07 454.00,0.00
             454.00,0.00 398.00,0.00 398.00,0.00
             394.26,0.01 390.45,-0.31 387.57,2.57
             384.07,6.07 381.56,19.75 396.00,20.00
             396.00,20.00 431.00,20.00 431.00,20.00 Z
           M 363.00,492.00
           C 363.00,492.00 308.00,492.00 308.00,492.00
             308.00,492.00 308.00,319.00 308.00,319.00
             307.99,315.10 308.05,311.76 306.75,308.00
             298.97,285.34 268.94,290.00 250.00,290.00
             240.21,290.00 226.56,289.08 218.00,294.28
             208.30,300.17 205.02,308.15 205.00,319.00
             205.00,319.00 205.00,492.00 205.00,492.00
             205.00,492.00 150.00,492.00 150.00,492.00
             150.00,492.00 150.00,404.00 150.00,404.00
             149.99,400.07 150.11,397.87 148.96,394.00
             141.93,370.36 116.28,374.00 97.00,374.00
             97.00,374.00 79.00,374.00 79.00,374.00
             70.05,374.04 60.78,375.59 54.18,382.18
             47.44,388.93 46.01,397.90 46.00,407.00
             46.00,407.00 46.00,492.00 46.00,492.00
             46.00,492.00 27.00,492.00 27.00,492.00
             24.05,492.01 20.75,491.79 18.11,493.31
             12.19,496.71 11.80,506.07 17.22,510.11
             20.10,512.25 23.61,511.99 27.00,512.00
             27.00,512.00 485.00,512.00 485.00,512.00
             488.39,511.99 491.90,512.25 494.78,510.11
             500.20,506.07 499.81,496.71 493.89,493.31
             490.11,491.14 472.36,492.00 467.00,492.00
             467.00,492.00 467.00,177.00 467.00,177.00
             466.99,172.14 466.99,169.77 465.56,165.00
             458.49,141.39 431.05,146.00 412.00,146.00
             412.00,146.00 395.00,146.00 395.00,146.00
             390.11,146.01 385.71,146.25 381.00,147.78
             372.41,150.58 365.94,158.30 364.02,167.00
             362.48,173.96 363.00,182.75 363.00,190.00
             363.00,190.00 363.00,226.00 363.00,226.00
             363.00,226.00 363.00,492.00 363.00,492.00 Z
           M 446.00,492.00
           C 446.00,492.00 384.00,492.00 384.00,492.00
             384.00,492.00 384.00,180.00 384.00,180.00
             384.01,175.97 383.61,171.47 387.23,168.74
             390.35,166.38 397.11,167.00 401.00,167.00
             401.00,167.00 433.00,167.00 433.00,167.00
             443.32,167.02 445.98,168.25 446.00,179.00
             446.00,179.00 446.00,243.00 446.00,243.00
             446.00,243.00 446.00,492.00 446.00,492.00 Z
           M 72.00,293.74
           C 58.47,301.02 68.01,316.77 79.00,312.96
             86.24,310.44 88.38,300.80 82.61,295.65
             79.44,292.82 75.86,292.84 72.00,293.74 Z
           M 288.00,492.00
           C 288.00,492.00 226.00,492.00 226.00,492.00
             226.00,492.00 226.00,323.00 226.00,323.00
             226.01,319.65 225.63,316.02 228.31,313.51
             231.11,310.90 235.44,311.02 239.00,311.00
             239.00,311.00 273.00,311.00 273.00,311.00
             276.83,311.01 282.38,310.78 285.30,313.70
             287.83,316.23 287.96,320.65 288.00,324.00
             288.00,324.00 288.00,353.00 288.00,353.00
             288.00,353.00 288.00,492.00 288.00,492.00 Z
           M 129.00,492.00
           C 129.00,492.00 67.00,492.00 67.00,492.00
             67.00,492.00 67.00,409.00 67.00,409.00
             67.01,405.30 66.65,400.45 69.51,397.65
             72.28,394.93 76.40,395.02 80.00,395.00
             80.00,395.00 115.00,395.00 115.00,395.00
             126.38,395.02 128.98,396.24 129.00,408.00
             129.00,408.00 129.00,492.00 129.00,492.00 Z"
      />
    </svg>
  )
}

export default Logo
